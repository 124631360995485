<script>
	export let items = [], _class= "", component = null
</script>


<ul class={"list-group list-custom"+_class}>
    {#each items as item (item.id)}
        <li class="list-group-item item-custom">
            <svelte:component this={component} {...item} />
        </li>
    {/each}
</ul>

<style>
	.list-custom {
		margin-bottom: 0 px;
		padding-bottom: 0 px;	
	}
    .item-custom {
        margin-bottom: 0 px;
    }
</style>