<script>
    import { server_url } from '../../config/config'
    
	export let id, name, icono, social_links;
</script>

<div class="row d-flex">
    <div class="col-12 text-center d-flex justify-content-center flex-wrap flex-sm-nowrap justify-content-md-start col-sm mb-2 mb-sm-0">
        <div>
            <img class="border rounded" src={server_url()+icono.url} alt={"periodico "+id}>
            <span class="d-block">{name}<br></span>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-wrap justify-content-sm-start justify-content-xl-start align-items-xl-start"
            style="width: 336px; padding-right: 0px;">
            {#each social_links as social_link (social_link.id)}
            <a class="btn btn-dark" role="button"  href={social_link.link}>
                <i class={social_link.social_link_type.icono}></i> {social_link.social_link_type.title}
            </a>
            {/each}
        </div>
    </div>
</div>

<style>
    span {
        font-size: 100%;
        color: rgb(0,0,0);
    }
    img {
        width: 100px;
        height: 100px;
    }
    a {
        background-color: rgb(0,0,0);
        margin: 5px;
        width: 120px;
        margin-top: 0px;
    }
</style>